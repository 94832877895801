<template>
  <div class="night" v-if="content">
        <div
            class="content1"
            :style="{'background-image':content.productStyle.content1.resourceUrl?`url(${content.productStyle.content1.resourceUrl})`:''}"
        >
            <div>
<!--              <div class="type">皓夜系列</div>-->
<!--              <div class="title">{{content.product.productName}}</div>-->
<!--              <div class="info">{{content.product.productDesc}}</div>-->

            </div>
        </div>
        <div class="content2"
        >
          <div class="title" v-animate-onscroll.repeat="{down:'downToTop'}">{{content.productStyle.content2.title}}</div>
          <div class="list-box" v-animate-onscroll.repeat="{down:'downToTop'}">
              <div class="list-item"
                   v-for="(item,index) in content.productStyle.content2.list"
                   :key="index"
              >
                  <div class="item-title">{{item.title}}</div>
                  <div class="item-text">{{item.description}}</div>
              </div>
          </div>
          <div style="margin-top: 20px">
            <video  :src="content.productStyle.content2.resourceUrl" autoplay="autoplay" loop="loop" muted width="1200px" height="600px"></video>
          </div>
        </div>
        <div class="content3" >
          <div v-animate-onscroll.repeat="{down:'downToTop'}">
            <div class="title">{{content.productStyle.content3.title}}</div>
            <div class="text">{{content.productStyle.content3.description}}</div>
          </div>
<!--              <video style="margin-top: 50px" :src="content.productStyle.content3.resourceUrl" autoplay="autoplay" loop="loop" muted width="1200px" height="600px"></video>-->
                  <img style="margin-top: 50px" :src="content.productStyle.content3.resourceUrl" alt="" width="1200px" height="600px">
        </div>
        <div class="content4">
              <div v-animate-onscroll.repeat="{down:'downToTop'}">
                <div class="title">{{content.productStyle.content4.title}}</div>
                <div class="text">{{content.productStyle.content4.description}}</div>
              </div>
            <div
                v-for="(item,index) in content.productStyle.content4.list"
                :key="index"
                v-show="activeIndex==index"
            >
              <video style="margin-top: 50px" :src="item.resourceUrl" autoplay="autoplay" loop="loop" muted width="1200px" height="600px"></video>
            </div>
            <div class="tools-box">
            <div class="tools-item"
                 v-for="(item,index) in content.productStyle.content4.list"
                 :class="activeIndex==index?'active':''"
                 :key="index"
                 @click="activeIndex=index"
            >
              {{item.title}}
            </div>
          </div>
        </div>
        <div class="content5" >
                <div v-animate-onscroll.repeat="{down:'downToTop'}">
                  <div class="title">{{content.productStyle.content5.title}}</div>
                  <div class="text">{{content.productStyle.content5.description}}</div>
                </div>
                <div class="list-box">
                    <div class="list-item"
                         v-for="(item,index) in content.productStyle.content5.list"
                         :key="index"
                    >
                      <div class="box-5" v-if="index%2==0">
                        <div class="item-info">
                          <div>
                            <div class="title">{{item.title}}</div>
                            <div class="item.info" style="color: #797979">{{item.description}}</div>
                          </div>
                        </div>
                        <div class="item-img">
                          <img :src="item.resourceUrl" width="100%" height="100%"  alt="">
                        </div>
                      </div>
                      <div class="box-5" v-else>
                        <div class="item-img">
                          <img :src="item.resourceUrl" width="100%" height="100%"  alt="">
                        </div>
                        <div class="item-info">
                          <div>
                            <div class="title">{{item.title}}</div>
                            <div class="item.info" style="color: #797979">{{item.description}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
        </div>
<!--        <div class="content6">-->
<!--                <div v-animate-onscroll.repeat="{down:'downToTop'}">-->
<!--                  <div class="title">{{content.productStyle.content6.title}}</div>-->
<!--                  <div class="text">{{content.productStyle.content6.description}}</div>-->
<!--                </div>-->
<!--                <div style="margin-top: 50px">-->
<!--                  <img width="1200px" height="760px" :src="content.productStyle.content6.resourceUrl" alt="">-->
<!--                </div>-->

<!--        </div>-->
        <div class="content7">
              <div class="title">{{content.productStyle.content7.title}}</div>
              <div class="text">{{content.productStyle.content7.description}}</div>
              <div
              v-for="(item,index) in content.productStyle.content7.list"
              :key="index"
              style="margin-top: 50px"
              v-show="listActive==index"
          >
                <img :src="item.resourceUrl" alt="" width="1200px" height="600px">
          </div>
              <div class="tools-box">
            <div class="tools-item"
                 v-for="(item,index) in content.productStyle.content7.list"
                 :class="listActive==index?'active':''"
                 :key="index"
                 @click="listActive=index"
            >
              {{item.title}}
            </div>
          </div>
        </div>
        <div class="info">
            <div class="title" style="margin-top: 128px">硬件参数</div>
            <div class="text-info">
                <span style="font-size: 32px">产品参数</span> <span style="margin-left: 50px;font-size: 24px"> 型号：{{content.product.productName}}</span>
            </div>
            <div class="ql-editor" v-html="content.productStyle.content8.productParameter"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "night",
  data(){
    return {
      content:null,
      activeIndex:0,
      listActive:0
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
  },
  methods:{
    getDetail(){
          this.$get('/website/product/style1/'+this.$route.query.id).then((res)=>{
              this.content=res.data;
          }).catch((err)=>{

          })
    },
  }
}
</script>
<style lang="scss">
@keyframes downToTop {
    0%{
      opacity: 0;
      transform: translateY(60px);
    }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}
.downToTop{
  animation: downToTop 1.5s;
}
</style>
<style scoped lang="scss">
.night{
  padding-bottom: 50px;
  background: black;
  color: #fff;
  .content1{
    width: 100%;
    height: 880px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    color: #fff;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .type{
      font-size: 32px;
      margin-bottom: 10px;
    }
    .info{
      font-size: 22px;
    }
    >div{
      text-align: center;
      padding-top: 130px;
    }
  }
  .content2{
    width: 100%;
    text-align: center;
    margin-top: 128px;
    .title{
      font-size: 48px;
      margin-bottom: 50px;
    }
    .list-box{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .list-item{
        margin-top: 20px;
        width: 20%;
        .item-title{
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .item-text{
          color: #797979;
        }
      }
    }
  }
  .content3{
    width: 100%;
    text-align: center;
    margin-top: 50px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }
  }
  .content4{
    width: 100%;
    text-align: center;
    margin-top: 50px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }
  }
  .content5{
    width: 100%;
    text-align: center;
    margin-top: 128px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }
    .list-box{
      .list-item{
        width: 1200px;
        margin: 0 auto;
        margin-top: 140px;
        >div{
          display: flex;
          justify-content: space-between;
          height: 520px;
          .item-img{
            width: 790px;
          }
          .item-info{
            width: 350px;
            text-align: left;
            display: flex;
            align-items: center;
          }
        }

      }
      .list-item:first-child{
        margin-top: 50px;
      }
    }
  }
  .content6{
    width: 100%;
    text-align: center;
    margin-top: 128px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }
  }
  .content7{
    width: 100%;
    text-align: center;
    margin-top: 128px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }

  }
  .tools-box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #3F3F3F;
    .tools-item{
      padding: 5px 10px;
      color: #5A5A5A;
      border-top: 1px solid transparent;
      cursor: pointer;
    }
    .tools-item.active{
      color: #fff;
      border-top: 1px solid #fff;
    }
  }
  .info{
    width: 1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
      text-align: center;

    }
  }
  .ql-editor{
    height: 430px;
    margin-top: 50px;
    background: #1A1A1A;
    color: #ABABAB;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background-color: #797979;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // border-radius: 10px;
      background: transparent;
    }
  }

}
</style>
